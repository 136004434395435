import React, { useState, useEffect } from 'react';
import Header from '../header/Header';
import { Link } from 'react-router-dom';

import Footer from '../footer/Footer';
import mainBg from '../assets/imgs/main-bg-img.png';

import otterize from '../assets/imgs/otterize/cover-img.png';
import arrows from '../assets/imgs/arrows/cover-img.png';
import navina from '../assets/imgs/navina/navina00.png';
import spark from '../assets/imgs/spark/cover-img.png';
import clariter from '../assets/imgs/clariter/clariter00.png';
import vayyar from '../assets/imgs/vayyar/cover-img.png';
import astrix from '../assets/imgs/astrix/cover-img.png';

import arrowIcn from '../assets/icns/arrow.svg';






const HomePage = () => {
    const [title, setTitle] = useState(['Bright Your Company', 'Bright Your Brand with a new Product Video', 'Bright Your Brand with a new Marketing Video', 'Bright Your Brand with a new Announcement Video']);
    const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % title.length);
      }, 2000); 
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }, [title]);
  




    const redirectToWorks = () => {
        window.location.href = '/works';
      };
      const redirectToContact = () => {
        window.location.href = '/contact';
      };
    const redirectToOtterize = () => {
        window.location.href = '/works/otterize';
      };
    const redirectToNavina = () => {
        window.location.href = '/works/navina';
      };
    const redirectToArrows = () => {
        window.location.href = '/works/arrows';
      };
    const redirectToSpark = () => {
        window.location.href = '/works/spark';
      };
      const redirectToVayyar = () => {
        window.location.href = '/works/vayyar';
      };
      const redirectToClariter = () => {
        window.location.href = '/works/clariter';
      };
      const redirectToAstrix = () => {
        window.location.href = '/works/astrix';
      };
      

  return (
    <div className='light-background row container maxWidth-1920 mx-auto'>
        <Header/>
        <div className='header-height'></div>

            <div className='row'>
                {/* <video
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '95%',
                            height: '85%',
                            objectFit: 'cover',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 0,
                        }}
                    className='m-0 p-0 border-15'
                    src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/hero.mp4"
                    autoPlay="autoplay"
                    controls=""
                    loop
                    muted
                    /> */}
                    {/* <img
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '95%',
                            height: '85%',
                            objectFit: 'cover',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 0,
                            
                        }}
                    className='m-0 p-0 border-15'
                    src={mainBg}

                    /> */}
                <div className='col-lg-7 col-md-11 col-sm-12 col-xs-12 px-5 mt-sm-5 mx-md-5'>
                    {/* <h4 className='fw-400 text-dark fs-60 capitalize'>
                        We bring brands, stories, and experiences to life through art, design, and technology
                    </h4> */}
                    <h4 className='fw-600 text-dark fs-3em capitalize'>
                        {title[0]}
                    </h4>
                    <p className='mt-4 fw-200 fs-32'>
                        By leveraging state-of-the-art visual experiences, innovative thinking, creative problem-solving, and data-driven insights, we create an excellent call to actions.
                    </p>
                
                    <div className=' d-inline-flex'>
                    <Link to="/contact" className=''>
                        <p className='m-0 p-0 text-dark capitalize fs-22 fw-600'>
                            Schedule a call
                        </p>
                        <img className='ps-2' width="30px" src={arrowIcn}/>

                    </Link>
                    </div>
                </div>       

                <div className='col pt-4 d-flex justify-content-end'>
                  <video
                      className='video'
                      src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/contact_page.mp4"
                      loop
                      muted
                      autoPlay
                      style={{ maxWidth: '100%' }}
                    />
                </div>    
            </div>



            <div className='row px-4 align-items-center h-70'>
                <div className='col-lg-9 col-md-10 col-sm-10 col-xs-12 mx-auto text-center'>
                    <h4 className='fs-60 fw-600 capitalize'>
                        Perfect for businesses looking to make a memorable first, second, third... impression
                    </h4>
                    <p className='mt-4 fs-24 fw-200'>
                    </p>
                </div>
            </div>


            <div className='row mb-5 px-5 align-items-center'>
                <div className='col-7 mx-auto text-center'>
                    <p className='mt-4 fs-24 fw-400 capitalize'>

                    Some of the recent works we created
                    </p>
                </div>
            </div>

            <div className='row px-5 m-0 align-items-end'>
                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToNavina} className='image-container pointer' >
                        <img className='img-fluid' src={navina} alt='otterize-cover' width={1920} height={1080}/>
                        </div>
                        <div className='my-2 text-dark'>
                            <h4 onClick={redirectToNavina} className='fs-18 fw-300 capitalize pointer'>Navina | Web Animations 24</h4>
                        </div>
                    </div>

                    <div className='col-sm-6 col-xs-12 p-3'>
                        <div onClick={redirectToClariter} className='image-container pointer' >
                        <img className='img-fluid' src={clariter} alt='arrows-cover' width={1920} height={1080}/>
                        </div>
                        <div className='my-2 text-dark'>
                            <h4 onClick={redirectToClariter} className='fs-18 fw-300 capitalize pointer'>Clariter | Service Explainers</h4>
                        </div>
                    </div>

                    <div className='col-sm-6 col-xs-1 p-3'>
                        <div onClick={redirectToVayyar} className='image-container pointer' >
                        <img className='img-fluid' src={vayyar} alt='navina-cover' width={1920} height={1080}/>
                        </div>
                        <div className='my-2 text-dark'>
                            <h4 onClick={redirectToVayyar} className='fs-18 fw-300 capitalize pointer'>Vayyar | App Interaction</h4>

                        </div>
                    </div>

                    <div className='col-sm-6 col-xs-12 p-3'>
                        <div onClick={redirectToOtterize} className='image-container pointer' >
                        <img className='img-fluid' src={otterize} alt='navina-cover' width={1920} height={1080}/>
                        </div>
                        <div className='my-2 text-dark'>
                            <h4 onClick={redirectToOtterize} className='fs-18 fw-300 capitalize pointer'>Otterize | Explainer Video 24</h4>

                        </div>
                    </div>

                    <div className='col-sm-6 col-xs-12 p-3'>
                        <div onClick={redirectToAstrix} className='image-container pointer' >
                        <img className='img-fluid' src={astrix} alt='arrows-cover' width={1920} height={1080}/>
                        </div>
                        <div className='my-2 text-dark'>
                            <h4 onClick={redirectToAstrix} className='fs-18 fw-300 capitalize pointer'>Astrix | Animated Explainer Video</h4>
                        </div>
                    </div>
                    
                    <div className='col-sm-6 col-xs-1 p-3'>
                        <div onClick={redirectToSpark} className='image-container pointer' >
                        <img className='img-fluid' src={spark} alt='navina-cover' width={1920} height={1080}/>
                        </div>
                        <div className='my-2 text-dark'>
                            <h4 onClick={redirectToSpark} className='fs-18 fw-300 capitalize pointer'>Spark | 2D Explainer Video</h4>

                        </div>
                    </div>



                    <div className='d-inline-flex col-12 text-center justify-content-top pt-4 mt-4'>
                        <h4 className='fs-24 fw-600 pointer '
                         onClick={redirectToWorks} 
                        >
                            More Works
                        </h4>
                        <img className='ps-2' width="30px" src={arrowIcn}/>

                    </div>
                </div>







            <div className='header-height py-5'></div>



            <div className='row mb-5 px-5'>
                <div className='col-12'>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h4 className='fs-24 fw-400 capitalize'>
                            Services we provide
                        </h4>
                    </div>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h6 className='fs-50 fw-400'>
                            Video Production
                        </h6>
                    </div>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h6 className='fs-50 fw-400'>
                            Animation Production
                        </h6>
                    </div>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h6 className='fs-50 fw-400'>
                            Service Design
                        </h6>
                    </div>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h6 className='fs-50 fw-400'>
                            Brand Design
                        </h6>
                    </div>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h6 className='fs-50 fw-400'>
                            Product Design
                        </h6>
                    </div>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h6 className='fs-50 fw-400'>
                            Service Design
                        </h6>
                    </div>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h6 className='fs-50 fw-400'>
                            Game Development
                        </h6>
                    </div>
                    <div className='border-bottom-dark pt-5 pb-5'>
                        <h6 className='fs-50 fw-400'>
                            Interface Development
                        </h6>
                    </div>
            </div>
        </div>


        <div className='header-height py-5'></div>



        <div className='row mb-5 px-5 '>
                <div className='col-8 mx-auto'>
                    <div className='text-center pt-5 pb-5'>
                        <h4 className='fs-60 fw-600 capitalize mb-4'>
                        Transform Your Brand Identity with Stunning Video Production
                        </h4>
                        <p className='fs-32 fw-200'>
                        Elevate your brand to the next level with our professional services! Our expertly crafted productions not only grab attention but also enhance user experience and engagement.
                        </p>
                        <div className='menu'>
                            <p className=' d-inline-flex mt-4 fs-24 fw-600 px-4 py-2 pointer capitalize'
                            onClick={redirectToContact} 
                            >
                            Schedule a call
                            </p>
                        </div>
                    </div>



{/* 
                <div className='row align-items-end'>
                    <div className='col-4 dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Healthcare</h4>
                    </div>
                    <div className='col-4 dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Technology</h4>
                    </div>
                    <div className='col-4 dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Education</h4>
                    </div>
                    <div className='col-4 dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Sustainable</h4>
                    </div>
                    <div className='col dark-background text-light m-2'>
                        <div className='my-5 py-5'></div>
                        <h4 className='fs-32 fw-200'>Healthcare</h4>
                    </div>
                </div> */}


                </div>
            </div>


        




            <div className='row mb-5 mt-5 px-5'>
                <div className='col-12'>
                    <div className='pt-5 pb-5'>
                        <h4 className='fs-60 fw-300 capitalize'>
                            Behind every project stand people. Together, we turn projects into trusting partnerships through our shared values & commitment
                        </h4>
                    </div>
                </div>
            </div>


            <div className='row px-4'>
               <div className='col-md col-sm col-xs-12 mt-3'>
                <p className='fs-18 m-0 p-0 fw-400'>Reach out</p>
                  <a className='fs-32 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                    <br></br>
                        <a className='fs-32 text-dark fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>
               </div>
               </div>

        <div className='mb-3'>
            <Footer/>
        </div>

   
        </div>

     
  );
};

export default HomePage;
